import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCustomersList() {
  // Use toast
  const toast = useToast()

  const refCustomerListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'ID', sortable: false },
    { key: 'MemberID', sortable: false },
    { key: 'Email', sortable: false },
    { key: 'Mobile', sortable: false },
    { key: 'RedeemGift1', sortable: false },
    { key: 'RedeemGift2', sortable: false },
    { key: 'RecycleStamp', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalCustomers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const memberIdFilter = ref('')
  const mobileFilter = ref('')
  const emailFilter = ref('')
  const RedeemGift1Filter = ref('All')
  const RedeemGift2Filter = ref('All')
  const ShowLoading = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerListTable.value ? refCustomerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomers.value,
    }
  })

  const refetchData = () => {
    refCustomerListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, memberIdFilter, mobileFilter, emailFilter, RedeemGift1Filter, RedeemGift2Filter], () => {
    refetchData()
  })

  const fetchCustomers = (ctx, callback) => {
    store
      .dispatch('app-report/fetchCustomers', {
        PerPage: perPage.value,
        Page: currentPage.value,
        MemberID: memberIdFilter.value,
        Mobile: mobileFilter.value,
        Email: emailFilter.value,
        RedeemGift1: RedeemGift1Filter.value,
        RedeemGift2: RedeemGift2Filter.value,
      })
      .then(response => {
        const { Data, Total } = response.data

        callback(Data)
        totalCustomers.value = Total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const downloadReport = () => {
    ShowLoading.value = true
    store
      .dispatch('app-report/downloadCustomerReport', {
        MemberID: memberIdFilter.value,
        Mobile: mobileFilter.value,
        Email: emailFilter.value,
        RedeemGift1: RedeemGift1Filter.value,
        RedeemGift2: RedeemGift2Filter.value,
      })
      // .then(response => {
      //   const { Data, Total } = response.data

      //   callback(Data)
      //   totalCustomers.value = Total
      // })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        ShowLoading.value = false
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCustomerRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveCustomerRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchCustomers,
    downloadReport,
    tableColumns,
    perPage,
    currentPage,
    totalCustomers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerListTable,
    ShowLoading,

    resolveCustomerRoleVariant,
    resolveCustomerRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    memberIdFilter,
    mobileFilter,
    emailFilter,
    RedeemGift1Filter,
    RedeemGift2Filter,
  }
}
